/* You can add global styles to this file, and also import other style files */

html {
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.main-container {
  margin: 1.5em;
}

.main-content {
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.widescreen .main-content {
  max-width: 100%;
}

.bp-menu__link {
  cursor: pointer;
  height: 45px;
}

.bp-menu__link > a, .bp-menu__link > a:hover {
  color: inherit;
}

.active-page {
  font-weight: 500;
}

@media only screen and (min-width: 640px) {
  .selected-products-scroll {
    overflow-x: auto;
    max-height: 50vh;
  }

  .sticky-content {
    position: fixed;
    padding-left: 0;
    padding-top: 0;
    width: 30%;
    max-height: 50vh;
  }

  @supports (position: sticky) {
    .sticky-content {
      position: sticky;
      top: 0;
      bottom: 0;
      max-height: 85vh;
      width: inherit;
      z-index: 2;
    }

    .selected-products-scroll {
      max-height: 85vh;
    }
  }

  .show-for-small-only {
    display: none !important;
  }

  .parentSubCategory {
    scroll-margin-top: 100px;
  }
}

@media only screen and (max-width: 640px) {
  .main-container {
    margin: 0.5em;
  }

  .modal {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100% !important;
    max-width: 100% !important;
  }

  .option-price {
    vertical-align: top !important;
  }

  .mat-radio-label, .mat-checkbox-layout {
    align-items: normal !important;
  }

  .mat-radio-container, .mat-checkbox-inner-container {
    margin-top: 3px !important;
  }

  cbs-category-menu {
    display: none;
  }

  .hide-for-small-only {
    display: none !important;
  }

  h1 {
    font-size: 1.25em !important;
  }

  h2, h3 {
    font-size: 1em !important;
  }
}

.p-none > .mat-menu-content {
  padding: 0 !important;
}

details img { /* overrides foxhound styles */
  display: inline-block !important;
  margin: 0 !important;
  width: auto !important;
}

.spinner-button {
  line-height: 1;
  font-size: 1.1em;
}

label {
  display: block;
  line-height: 1.5;
}

button { /* base styles for buttons (mainly used for icon buttons) */
  border: none;
  outline: none;
  cursor: pointer;
  background-color: inherit;
  padding: 0;
}

.popover {
  white-space: normal !important; /* override no-wrap cell behavior */
}

.mat-menu-panel {
  max-width: 100% !important;
}

.mat-menu-item:not(:hover)  {
  color: inherit;
  background-color: inherit !important;
}
